<template>
  <div class="indexStyle">
        <div class="head_img" :style="`height:${banner_height}px`"></div>
        <div class="trait">
            <div class="h2">功能特征</div>
            <div class="content">全行业覆盖,全行业精美模板,零门槛拖拽制作,三分钟生成专属小程序</div>
            <ul>
                <li v-for="(item,index) in traitList" :key="index">
                    <img :src="item.img" alt="">
                    <div class="title">{{item.title}}</div>
                    <div class="content">
                        <p>{{item.content1}}</p>
                        <p>{{item.content2}}</p>
                    </div>
                </li>
                <div class="list" v-for="item in (rowNum - traitList.length % rowNum)"><div v-if="traitList.length % rowNum > 0"></div></div>
            </ul>
        </div>
        <computerCase :title="'用户端截图预览'" :deskShow="deskShow" :list="userList" />
        <computerCase :title="'后台截图预览'" :deskShow="deskShow" :list="caseList" :bgColor="'#fff'" />
        <correlationCase  :caseShow="caseShow"  :caseList="hairdressing" />
  </div>
</template>

<script>
import correlationCase from '@/components/correlationCase'
import computerCase from '@/components/computerCase'
export default {
    components:{correlationCase,computerCase},
    data(){
        return{
            traitList:[
                {
                    img:require('@/assets/image/enterprise/icon1.png'),
                    title:'可视化后台',
                    content1:'ppt式操作',
                    content2:'简单易用'
                },
                {
                    img:require('@/assets/image/enterprise/icon2.png'),
                    title:'多站合一',
                    content1:'PC+手机+iPad+TV',
                    content2:'自适应设备'
                },
                {
                    img:require('@/assets/image/enterprise/icon3.png'),
                    title:'SEO优化',
                    content1:'关键词设置',
                    content2:'排名分析'
                },
                {
                    img:require('@/assets/image/enterprise/icon4.png'),
                    title:'万能表单',
                    content1:'集成投票、问卷、',
                    content2:'报名等应用'
                },
                {
                    img:require('@/assets/image/enterprise/icon5.png'),
                    title:'会员管理',
                    content1:'信息管理/会员分级',
                    content2:'分权浏览'
                },
                {
                    img:require('@/assets/image/enterprise/icon6.png'),
                    title:'多语言',
                    content1:'简、繁、英等10种',
                    content2:'语言切换'
                },
                {
                    img:require('@/assets/image/enterprise/icon(6).png'),
                    title:'订单管理',
                    content1:'在线交易、支付、',
                    content2:'物流配送'
                },
                {
                    img:require('@/assets/image/enterprise/icon(7).png'),
                    title:'微信生态',
                    content1:'公众号托管',
                    content2:'小程序一键生成'
                },
                {
                    img:require('@/assets/image/enterprise/icon(14).png'),
                    title:'精美排版',
                    content1:'1000+免费使用',
                    content2:'想换就换'
                },
                {
                    img:require('@/assets/image/enterprise/icon(9).png'),
                    title:'资料库',
                    content1:'视频、文件资料',
                    content2:'上传下载'
                },
                {
                    img:require('@/assets/image/enterprise/icon(10).png'),
                    title:'自动备份',
                    content1:'提供自动备份',
                    content2:'手动备份'
                },
                {
                    img:require('@/assets/image/enterprise/icon(11).png'),
                    title:'资源空间',
                    content1:'最高空间3T',
                    content2:'访问不限量'
                },
                {
                    img:require('@/assets/image/enterprise/icon(12).png'),
                    title:'在线客服',
                    content1:'在线QQ、旺旺、',
                    content2:'Skype、百度商桥'
                },
                {
                    img:require('@/assets/image/enterprise/icon(13).png'),
                    title:'多应用配置',
                    content1:'职位招聘/邮件营销',
                    content2:'第三方代码'
                },

            ],
            //酒店管理
            hairdressing:[
                {
                    img:require('@/assets/image/case/17.png'),
                    title:'酒店管理系统',
                    content1:'花筑旅行',
                    content2:'酒店管理系统大平台，连锁酒店，中小酒店，轻松满足'
                },
                {
                    img:require('@/assets/image/case/18.png'),
                    title:'酒店管理系统',
                    content1:'艺龙会酒店',
                    content2:'信息化、网络化、规范化，高效性执行酒店信息管理'
                },
                {
                    img:require('@/assets/image/case/19.png'),
                    title:'酒店管理系统',
                    content1:'万爱情侣酒店',
                    content2:'以物联网、云计算为基础建设，提供酒店智能化管理'
                },
                {
                    img:require('@/assets/image/case/20.png'),
                    title:'酒店管理系统',
                    content1:'飞火电竞',
                    content2:'为管理层提供有效数据依据，指导决策的酒店信息化'
                },
            ],
            userList:[
                require('@/assets/image/enterprise/1.png'),
                require('@/assets/image/enterprise/2.png'),
                require('@/assets/image/enterprise/3.png'),
            ],
            caseList:[
                require('@/assets/image/enterprise/4.png'),
                require('@/assets/image/enterprise/5.png'),
                require('@/assets/image/enterprise/6.png'),
            ],
            rowNum:9,
            caseShow:false,
            deskShow:false,
            windowWidth:document.documentElement.clientWidth,
            banner_height: document.documentElement.clientWidth > 1024 ?
            document.documentElement.clientWidth * 0.21 : document.documentElement.clientWidth * 0.29,
        }
    },
    mounted(){
        window.addEventListener('scroll', this.handleScroll, true);  // 监听（绑定）滚轮滚动事件
        window.onresize = () => {
            return (() => {
                window.fullWidth = document.documentElement.clientWidth
                this.windowWidth = window.fullWidth // 宽
                if(this.windowWidth >= 1024){
                    this.banner_height = this.windowWidth * 0.21
                }else{
                    this.banner_height = this.windowWidth * 0.29
                }
            })()
        }
        if(this.windowWidth <= 1024){
            this.deskShow = true
            this.caseShow = true
        } 
    },
    methods:{        
        // 滚动监听 · 动画播放
        handleScroll(){ 
            if(this.windowWidth <= 1024)return
                // 页面滚动距顶部距离
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || 
                    document.body.scrollTop
            //动画播放设置
            
            let desk = document.getElementsByClassName('desk')
            let slidShow = document.getElementsByClassName('slidShow')
           //开发案例
            let Case = document.getElementsByClassName('case')
            let Case_h2 = Case[0].getElementsByClassName('h2')
            let Case_swiper = Case[0].getElementsByClassName('case-swiper-container')

            if(scrollTop > (desk[0].getBoundingClientRect().top + scrollTop) - desk[0].offsetHeight /2){
                this.deskShow = true
                desk[0].classList.add('animated','fadeIn')
                slidShow[0].classList.add('animated','zoomIn')
            }
            if(scrollTop > (Case[0].getBoundingClientRect().top + scrollTop) - Case[0].offsetHeight / 1.5){
                this.caseShow = true
                Case_h2[0].classList.add('animated','fadeIn')
                Case_swiper[0].classList.add('animated','fadeInUp')
            }
            
        } 
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll, true);
    }
}
</script>

<style lang="less" scoped>
    .indexStyle {
        .head_img{
            background: url('~@/assets/image/enterprise.png') no-repeat;
            background-size: 100%;
        }
        .trait{
            height: 1440px;
            .h2{
                padding: 80px 0 40px;
            }
            .content{
                margin-bottom: 50px;
            }
            ul{
                display: flex;
                flex-wrap: wrap;
                width: 1200px;
                margin: 0 auto;
                li{
                    background: url('~@/assets/image/enterprise/icon(16).png') no-repeat;
                    width: 296px;
                    height: 279px;
                    &:hover{
                        background: url('~@/assets/image/enterprise/icon(15).png') no-repeat;
                    }
                    img{
                        margin: 45px 0 22px 0;
                    }
                    .title{
                        font-size: 22px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #333333;
                        margin-bottom: 15px;
                    }
                    .content{
                        width: 100%;
                        font-size: 14px;
                        font-family: Source Han Sans CN;
                        font-weight: 300;
                        color: #666666;
                        line-height: 20px;
                    }
                }
                .list{
                    content: '';
                    width: 296px;
                    height: 279px;
                    border: 1px solid transparent;
                    padding: 5px;
                    overflow: hidden;
                }

            }
            
        }
        .fadeIn,.zoomIn{
            // animation-duration: 5s;         //动画持续时间
            animation-delay: 2s !important;              //动画延迟时间
            // animation-iteration-count: 2 !important;
        }
        .fadeInRight,.fadeInLeft,.fadeInUp{
            // animation-duration: 5s;         //动画持续时间
            animation-delay: 1.5s !important;              //动画延迟时间
            // animation-iteration-count: 2 !important;
        }
    }
@media screen and (max-width: 1024px) {
    .indexStyle {
        .head_img{
            height: 110px;
            background: url('~@/assets/image/mobile_enterprise.png') no-repeat;
            background-size: 100%;
        }         
        .trait{
            height: 795px;
            .h2{
                padding: 30px 0 12px 0;
            }
            .content{
                margin-bottom: 20px;
            }
            ul{
                width: 100% ;
                justify-content: center;
                li{
                    width: 108px;
                    height: 128px;
                    // margin: 0 8px;
                    // margin-bottom: 8px;
                    background-size: 108px 128px;
                    &:hover{
                        background-size: 108px 128px;
                    }
                    img{
                        max-width: 50%;
                        max-height: 30%;
                        margin: 15px 0 2px 0;
                    }
                    .title{
                        font-size: 13px;
                        margin-bottom: 5px;
                    }
                    .content{
                        font-size: 9px;
                        transform: scale(0.9);
                        line-height: 15px;
                    }
                }
                .list{
                    width: 108px;
                    height: 128px;
                }
            }
        }
    }
}
@media screen and (min-width: 1025px) {
    .indexStyle {
        margin-top: 90px;
    }
}
</style>